import type { UITag } from "../";
import { ClientOnly } from "remix-utils/client-only";
import { Popover, TagComponent } from "@fscrypto/ui";
import { useEffect, useRef, useState } from "react";
import { useResizeObserver } from "../../../hooks/useResizeObserver";

interface SingleTagRowProps {
  tags: UITag[];
}

function SingleTagRowInner({ tags }: SingleTagRowProps) {
  const tagsRef = useRef<Map<string, HTMLElement>>(new Map());
  const tagsRowRef = useRef<HTMLDivElement>(null);
  const [notTopRow, setNotTopRow] = useState<string[]>([]);
  const { width } = useResizeObserver(tagsRowRef);
  const [topRowWidth, setTopRowWidth] = useState<number>(0);
  const moreIndicatorLeftOffset =
    (tags && 0 - (width - (topRowWidth + (tags.length - notTopRow.length - 1) * 8))) /* 8px is the tag gap */ || 0;

  useEffect(() => {
    setNotTopRow([]);
    setTopRowWidth(0);
    let topRowYOffset: number | undefined;

    tagsRef.current.forEach((elm, key) => {
      if (elm) {
        const elmY = elm.getBoundingClientRect().top;

        // Capture top row offset
        if (!topRowYOffset || elmY < topRowYOffset) {
          topRowYOffset = elmY;
        }

        // If a tag has a greater Y offset than the top row offset, count it as not being
        // in the top row; otherwise add it's width to the top row width
        if (elmY > topRowYOffset) {
          setNotTopRow((prev) => [...prev, key]);
        } else {
          setTopRowWidth((prev) => prev + elm.getBoundingClientRect().width);
        }
      }
    });
  }, [tags, width]);

  return (
    <>
      <div className="flex max-h-5 overflow-hidden flex-wrap gap-x-2" ref={tagsRowRef}>
        {tags.map((tag) => (
          <TagComponent
            key={tag.name}
            ref={(element) => tagsRef.current.set(tag.name, element as HTMLElement)}
            size="xs"
            tag={tag}
          />
        ))}
      </div>
      {notTopRow.length > 0 && (
        <Popover.Root>
          <Popover.Trigger asChild>
            <TagComponent
              className="relative cursor-pointer"
              size="xs"
              style={{ transform: `translateX(${moreIndicatorLeftOffset}px)` }}
              tag={{ name: `+${notTopRow.length}` }}
            />
          </Popover.Trigger>
          <Popover.Content>
            <div className="flex flex-col space-y-2 p-4">
              {notTopRow.map((tagName) => (
                <TagComponent key={tagName} size="xs" tag={tags.find((t) => t.name === tagName)!} className="w-fit" />
              ))}
            </div>
          </Popover.Content>
        </Popover.Root>
      )}
    </>
  );
}

/**
 * @summary A single row of tags.
 * @desc Displays a more indicator (with count) if there are more tags than can fit in the row. Responds to width changes.
 * @param tags - The list of tags to display.
 */
export const SingleTagRow = (props: SingleTagRowProps) => {
  return <ClientOnly>{() => <SingleTagRowInner {...props} />}</ClientOnly>;
};
